"use client";

import { useContext } from "react";
import { ModalContext } from "@/context/ModalContext";
import { IModalActions, ModalTypes } from "@/types/modal";
import { getGlobalStateValue, updateStateSession } from "@/context/utils";
import style from "./ClientSideButton.module.scss";

interface CommonButtonProps {
  className?: string;
  modalData?: Record<string, any>;
  modalType?: ModalTypes;
  isEventOnly?: boolean;
  closeModal?: boolean;
  GADataNew?: Record<string, any>;
  webEngageEvents?: Record<string, any>;
  GAData?: Record<string, any>;
  sessionStateUpdate?: Record<string, any>;
  styles?: Record<string, any>;
  programTitle?: string;
  ctaText?:string;
  isScholarShipRedirection?:boolean;
  isArrowRequired?:boolean;
}

const ClientSideButton: React.FC<CommonButtonProps> = ({
  modalType,
  modalData = {},
  GADataNew = {},
  className = "",
  sessionStateUpdate = {},
  styles = {},
  programTitle = "", 
  ctaText = "APPLY NOW",
  isScholarShipRedirection=false,
  webEngageEvents = {},
  isArrowRequired = true
}) => {
  const { modalDispatch } = useContext(ModalContext);

  const fireGtEvents = () => setTimeout(() => {
    
    if (Object.keys(GADataNew).length > 0) {
      updateStateSession({ ...sessionStateUpdate });

      const { isPageType = false, page_url = false, ...restGADataNew } = GADataNew || {};
      const dataLayerData = {
        ...restGADataNew,
        ...(isPageType && { page_type: getGlobalStateValue("pageType") }),
        ...(page_url && { page_url: window.location.href }),
      };

      (window as any).dataLayer.push(dataLayerData);


    }

    if(Object.keys(webEngageEvents).length){
      const { isPageType = false, ...rest } = webEngageEvents || {};
      updateStateSession({ ...sessionStateUpdate });

      window.webengage.track("Apply Now Clicked", {
        ...rest,
        ...(isPageType && { "Page Type": getGlobalStateValue("pageType") }),
      });
      
    }
    
  }, 2000);

  const onClickHandler = () => {
    if(isScholarShipRedirection){
      window.location.href='/scholarship'
      return;
    }

    modalDispatch({
      type: IModalActions.OpenModal,
      data: {
        modalType,
        modalData: { ...modalData, href: "#", programTitle },
      },
    });

    fireGtEvents();
  };

  return (
    <button className={style.btnContainer} onClick={onClickHandler} style={styles}>
      <span className={style.btnText}>{ctaText}</span>

     {isArrowRequired && <svg
        width={16}
        height={16}
        viewBox="0 0 19 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <g>
            <g>
              <path
                d="M1 7.91907L18 7.91907"
                stroke="#1A325D"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <path
                d="M11.271 1.27179L17.8596 7.86042"
                stroke="#1A325D"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <path
                d="M11.271 14.4493L17.8596 7.86065"
                stroke="#1A325D"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </g>
          </g>
        </g>
      </svg>}
    </button>
  );
};

export default ClientSideButton;
